// File: src/components/AudioLyricsGenerator/CircularProgressWithLabel.js

import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const CircularProgressWithLabel = ({ value, size = 250 }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        thickness={2}
        sx={{
          color: 'primary.main',
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'round',
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ><a style={{ width: '100%', height: '80%', objectFit: 'contain' }} href="https://optagonen.se" target="_blank" rel="noopener noreferrer">
        <img
          src="https://cdn.optagonen.se/wp-content/uploads/2019/09/Artboard-1-01.svg"
          alt="Logo"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
        </a>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;