// File: src/components/AudioLyricsGenerator/LyricsDisplay.js

import React, { useMemo, useCallback, useEffect } from 'react';
import { Paper, Tabs, Tab, Box, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { parseTimestamp } from '../../utils/timeFormatters';

const LyricsDisplay = ({ transcript, generatedLyrics, tabValue, setTabValue, currentTime, generateLyrics }) => {
  const parseLyrics = useCallback((text) => {
    if (!text) return [];
    return text.split('\n').map(line => {
      const match = line.match(/\[(\d+:\d+)\]\s*(.*)/);
      if (match) {
        const [, timeStr, content] = match;
        const [minutes, seconds] = timeStr.split(':').map(Number);
        const time = minutes * 60 + seconds;
        return { time, text: content.trim() };
      }
      return null;
    }).filter(Boolean);
  }, []);

  const parsedTranscript = useMemo(() => {
    console.log("Parsing transcript:", transcript);
    const parsed = parseLyrics(transcript);
    console.log("Parsed transcript:", parsed);
    return parsed;
  }, [transcript, parseLyrics]);

  const parsedLyrics = useMemo(() => {
    console.log("Parsing generated lyrics:", generatedLyrics);
    const parsed = parseLyrics(generatedLyrics);
    console.log("Parsed lyrics:", parsed);
    return parsed;
  }, [generatedLyrics, parseLyrics]);

  useEffect(() => {
    console.log("LyricsDisplay - tabValue:", tabValue, "currentTime:", currentTime, "transcript length:", parsedTranscript.length, "lyrics length:", parsedLyrics.length);
  }, [tabValue, currentTime, parsedTranscript, parsedLyrics]);

  const isLineActive = useCallback((line, index, lyrics) => {
    const nextLine = lyrics[index + 1];
    const lineStartTime = line.time;
    const lineEndTime = nextLine ? nextLine.time : Infinity;
    return currentTime >= lineStartTime && currentTime < lineEndTime;
  }, [currentTime]);

  const renderLyrics = (lyrics) => {
    console.log("Rendering lyrics:", lyrics);
    return (
      <AnimatePresence>
        {lyrics.map((line, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <Typography
              sx={{
                mb: 1,
                p: 1,
                borderRadius: 2,
                backgroundColor: isLineActive(line, index, lyrics) ? 'primary.main' : 'background.paper',
                color: isLineActive(line, index, lyrics) ? 'primary.contrastText' : 'text.primary',
                fontWeight: isLineActive(line, index, lyrics) ? 'bold' : 'normal',
                transition: 'all 0.3s ease',
                transform: isLineActive(line, index, lyrics) ? 'scale(1.05)' : 'scale(1)',
              }}
            >
              {`[${Math.floor(line.time / 60)}:${(line.time % 60).toString().padStart(2, '0')}] ${line.text}`}
            </Typography>
          </motion.div>
        ))}
      </AnimatePresence>
    );
  };

  console.log("Rendering LyricsDisplay component");

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 4, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} sx={{ mb: 2 }}>
        <Tab label="Transkription" />
        {generateLyrics && <Tab label="Genererade låttexter" />}
      </Tabs>
      <Box sx={{ flexGrow: 1, overflow: 'visable' }}>
        {tabValue === 0 && (
          <>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Transcript lines: {parsedTranscript.length}
            </Typography>
            {renderLyrics(parsedTranscript)}
          </>
        )}
        {tabValue === 1 && generateLyrics && (
          <>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Generated lyrics lines: {parsedLyrics.length}
            </Typography>
            {renderLyrics(parsedLyrics)}
          </>
        )}
      </Box>
    </Paper>
  );
};

export default React.memo(LyricsDisplay);