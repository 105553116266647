import React from 'react';
import { 
  Box, 
  IconButton, 
  Slider, 
  TextField, 
  Button, 
  FormControlLabel, 
  Checkbox, 
  CircularProgress, 
  Typography,
  LinearProgress
} from '@mui/material';
import { 
  PlayArrow, 
  Pause, 
  SkipNext, 
  SkipPrevious, 
  VolumeUp,
  CloudUpload, 
  Mic, 
  MusicNote, 
  Brightness4, 
  Brightness7, 
  YouTube
} from '@mui/icons-material';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { formatTime } from '../../utils/timeFormatters';


const AudioControls = ({
  currentTime,
  duration,
  isPlaying,
  volume,
  youtubeUrl,
  isProcessing,
  generateLyrics,
  isUploading,
  uploadProgress,
  audioFile,
  apiKey,
  isDarkMode,
  togglePlayPause,
  handleVolumeChange,
  handleYoutubeUrlChange,
  onProcessYoutube,
  handleFileChange,
  setGenerateLyrics,
  processAudioAndGenerateLyrics,
  handleApiKeyChange,
  toggleDarkMode,
  audioRef,
  setCurrentTime,
  isTranscribing,
  isGeneratingLyrics,
   }) => {

  const handleSkip = (seconds) => {
    console.log(`Skipping ${seconds} seconds`);
    if (audioRef.current) {
      audioRef.current.currentTime += seconds;
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleProcessAudio = () => {
    console.log("handleProcessAudio called");
    if (audioFile) {
      console.log("Processing audio file:", audioFile);
      processAudioAndGenerateLyrics(audioFile);
    } else {
      console.log("No audio file selected");
      // You might want to show an error message to the user here
    }
  };

  const handleProcessYoutube = () => {
    console.log("handleProcessYoutube called");
    if (youtubeUrl) {
      console.log("Processing YouTube URL:", youtubeUrl);
      onProcessYoutube(youtubeUrl);
    } else {
      console.log("No YouTube URL entered");
      // You might want to show an error message to the user here
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <CircularProgressWithLabel value={(currentTime / duration) * 100}  audioRef={audioRef} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography>{formatTime(currentTime)}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => handleSkip(-10)}>
            <SkipPrevious />
          </IconButton>
          <IconButton onClick={togglePlayPause} sx={{ mx: 1 }}>
            {isPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>
          <IconButton onClick={() => handleSkip(10)}>
            <SkipNext />
          </IconButton>
        </Box>
        <Typography>{formatTime(duration)}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <VolumeUp />
        <Slider
          value={volume}
          onChange={handleVolumeChange}
          aria-labelledby="volume-slider"
          min={0}
          max={1}
          step={0.01}
          sx={{ ml: 2 }}
        />
      </Box>
      <TextField
        fullWidth
        label="YouTube URL"
        variant="outlined"
        value={youtubeUrl}
        onChange={handleYoutubeUrlChange}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        onClick={handleProcessYoutube}
        startIcon={<YouTube />}
        fullWidth
        sx={{ mb: 2 }}
        disabled={isProcessing || !youtubeUrl}
      >
        Bearbeta YouTube-länk
      </Button>
      <Button
        variant="contained"
        component="label"
        startIcon={<CloudUpload />}
        fullWidth
        sx={{ mb: 2 }}
        disabled={isProcessing}
      >
        Välj ljudfil
        <input
          type="file"
          hidden
          accept=".mp3,.wav,.aiff"
          onChange={handleFileChange}
        />
      </Button>
      
      {isUploading && (
        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}
      
      {isTranscribing && (
        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress />
          <Typography>Transkriberar...</Typography>
        </Box>
      )}
      
      {isGeneratingLyrics && (
        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress color="secondary" />
          <Typography>Genererar låttexter...</Typography>
        </Box>
      )}
      
      <FormControlLabel
        control={
          <Checkbox
            checked={generateLyrics}
            onChange={(e) => setGenerateLyrics(e.target.checked)}
            name="generateLyrics"
            color="primary"
          />
        }
        label="Generera även låttext"
      />
      <Button
        variant="contained"
        onClick={handleProcessAudio}
        startIcon={generateLyrics ? <MusicNote /> : <Mic />}
        fullWidth
        sx={{ mt: 2, mb: 2 }}
        disabled={!audioFile || isProcessing}
      >
        {isProcessing 
          ? <CircularProgress size={24} color="inherit" /> 
          : generateLyrics 
            ? 'Transkribera och generera låttext' 
            : 'Transkribera ljud'
        }
      </Button>
      <TextField
        type="password"
        fullWidth
        label="OpenAI API-nyckel"
        variant="outlined"
        value={apiKey}
        onChange={handleApiKeyChange}
        sx={{ mb: 2 }}
      />
      <IconButton onClick={toggleDarkMode} sx={{ position: 'absolute', top: 16, right: 16 }}>
        {isDarkMode ? <Brightness7 /> : <Brightness4 />}
      </IconButton>
    </Box>
  );
};

export default AudioControls;