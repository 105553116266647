// File: src/components/AudioLyricsGenerator/index.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { Container, Box, Typography, Grid, Paper,LinearProgress } from '@mui/material';
import { Toaster } from 'react-hot-toast';

import AudioControls from './AudioControls';
import LyricsDisplay from './LyricsDisplay';
import DynamicBackground from '../DynamicBackground';
import { getTheme } from '../../utils/theme';
import { useAudioProcessor } from '../../hooks/useAudioProcessor';

const SwedishAudioLyricsGenerator = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const audioRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    audioFile,
    transcript,
    generatedLyrics,
    apiKey,
    currentTime,
    duration,
    isPlaying,
    volume,
    youtubeUrl,
    isProcessing,
    generateLyrics,
    isUploading,
    uploadProgress,
    handleApiKeyChange,
    handleFileChange,
    togglePlayPause,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleVolumeChange,
    handleYoutubeUrlChange,
    processYoutubeLink,
    processAudioAndGenerateLyrics,
    setGenerateLyrics,
    setCurrentTime,
    isTranscribing,
    isGeneratingLyrics
    
  } = useAudioProcessor(audioRef);

 useEffect(() => {
    if (transcript) {
      console.log("Transcript changed, setting tabValue to 0");
      setTabValue(0);
    }
  }, [transcript]);

  useEffect(() => {
    if (generatedLyrics) {
      console.log("Generated lyrics changed, setting tabValue to 1");
      setTabValue(1);
    }
  }, [generatedLyrics]);

  useEffect(() => {
    console.log("Current tabValue:", tabValue);
  }, [tabValue]);

  useEffect(() => {
    setIsLoading(isTranscribing || isGeneratingLyrics);
  }, [isTranscribing, isGeneratingLyrics]);
  useEffect(() => {
    console.log("Main component - Current time:", currentTime);
  }, [currentTime]);

   useEffect(() => {
    console.log("Transcript changed:", transcript ? "exists" : "does not exist");
    if (transcript && !generateLyrics) {
      setTabValue(0); // Switch to transcript tab when transcript is ready and we're not generating lyrics
    }
  }, [transcript, generateLyrics]);

  useEffect(() => {
    console.log("Generated Lyrics changed:", generatedLyrics ? "exists" : "does not exist");
    if (generatedLyrics) {
      console.log("Setting tabValue to 1");
      setTabValue(1); // Switch to lyrics tab when lyrics are generated
    }
  }, [generatedLyrics]);

  useEffect(() => {
    console.log("TabValue changed:", tabValue);
  }, [tabValue]);

  const handleTabChange = (event, newValue) => {
    console.log("Tab changed to:", newValue);
    setTabValue(newValue);
  };

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode');
    setIsDarkMode(savedDarkMode !== 'false');
  }, []);

  const toggleDarkMode = useCallback(() => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode);
      return newMode;
    });
  }, []);

  const theme = getTheme(isDarkMode);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DynamicBackground />
      <Toaster position="top-center" reverseOrder={false} />
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            OW | Song Till Lyrics (YouTube inkluderat)
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3, borderRadius: 4, position: 'relative' }}>
                <AudioControls
                  currentTime={currentTime}
                  duration={duration}
                  isPlaying={isPlaying}
                  volume={volume}
                  youtubeUrl={youtubeUrl}
                  isProcessing={isProcessing}
                  generateLyrics={generateLyrics}
                  isUploading={isUploading}
                  uploadProgress={uploadProgress}
                  audioFile={audioFile}
                  apiKey={apiKey}
                  isDarkMode={isDarkMode}
                  togglePlayPause={togglePlayPause}
                  handleVolumeChange={handleVolumeChange}
                  handleYoutubeUrlChange={handleYoutubeUrlChange}
                  onProcessYoutube={processYoutubeLink}
                  handleFileChange={handleFileChange}
                  setGenerateLyrics={setGenerateLyrics}
                  processAudioAndGenerateLyrics={processAudioAndGenerateLyrics}
                  handleApiKeyChange={handleApiKeyChange}
                  toggleDarkMode={toggleDarkMode}
                  audioRef={audioRef}
                  setCurrentTime={setCurrentTime}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
       <Paper elevation={3} sx={{ p: 3, borderRadius: 4, height: '100%', display: 'flex', flexDirection: 'column' }}>
          {isTranscribing && <LinearProgress />}
          {isGeneratingLyrics && <LinearProgress color="secondary" />}
          <LyricsDisplay
      transcript={transcript}
      generatedLyrics={generatedLyrics}
      tabValue={tabValue}
      setTabValue={setTabValue}
      currentTime={currentTime}
      generateLyrics={generateLyrics}
    />
        </Paper>
      </Grid>
          </Grid>
        </Box>
      </Container>
      <audio 
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        style={{ display: 'none' }}
      />
    </ThemeProvider>
  );
};

export default SwedishAudioLyricsGenerator;