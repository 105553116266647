// File: src/utils/timeFormatters.js

export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

export const formatTimestamp = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export const parseTimestamp = (timestamp) => {
  const [minutesSeconds, milliseconds = '000'] = timestamp.split('.');
  const [minutes, seconds] = minutesSeconds.split(':').map(Number);
  return minutes * 60 + seconds + Number(`0.${milliseconds}`);
};