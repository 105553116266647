// File: src/hooks/useAudioProcessor.js
import { useState, useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { formatTimestamp, formatTime } from '../utils/timeFormatters';
const apiUrl = process.env.REACT_APP_API_URL;


export const useAudioProcessor = (audioRef) => {
  const [audioFile, setAudioFile] = useState(null);
  const [transcript, setTranscript] = useState('');
  const [generatedLyrics, setGeneratedLyrics] = useState('');
  const [apiKey, setApiKey] = useState(localStorage.getItem('openaiApiKey') || '');
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [generateLyrics, setGenerateLyrics] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isGeneratingLyrics, setIsGeneratingLyrics] = useState(false);


  useEffect(() => {
    const savedApiKey = localStorage.getItem('openaiApiKey');
    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
  }, []);

  const handleApiKeyChange = useCallback((e) => {
    const newApiKey = e.target.value;
    setApiKey(newApiKey);
    localStorage.setItem('openaiApiKey', newApiKey);
  }, []);

  

  const togglePlayPause = useCallback(() => {
    console.log("togglePlayPause called");
    console.log("audioRef:", audioRef);
    if (audioRef.current) {
      console.log("Audio ready state:", audioRef.current.readyState);
      if (isPlaying) {
        console.log("Pausing audio");
        audioRef.current.pause();
      } else {
        console.log("Playing audio");
        audioRef.current.play().then(() => {
          console.log("Audio playback started successfully");
        }).catch(error => {
          console.error('Uppspelningsfel:', error);
          toast.error('Kunde inte spela upp ljudet. Kontrollera filen och försök igen.');
        });
      }
      setIsPlaying(!isPlaying);
    } else {
      console.error("audioRef.current is null");
    }
  }, [isPlaying, audioRef]);

  const handleFileChange = useCallback((event) => {
    console.log("File change event:", event);
    const file = event.target.files[0];
    if (file && (file.type === 'audio/mpeg' || file.type === 'audio/wav' || file.type === 'audio/aiff')) {
      console.log("Valid audio file selected:", file);
      setAudioFile(file);
      if (audioRef.current) {
        const fileURL = URL.createObjectURL(file);
        console.log("Created file URL:", fileURL);
        audioRef.current.src = fileURL;
        audioRef.current.load();
        setDuration(0);
        setCurrentTime(0);
        console.log("Audio source set and loaded");
      } else {
        console.error("audioRef.current is null");
      }
      toast.success('Ljudfil uppladdad');
    } else {
      console.error("Invalid file type:", file ? file.type : "No file selected");
      toast.error('Vänligen välj en mp3, wav eller aiff fil.');
    }
  }, [audioRef]);


  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      const newTime = audioRef.current.currentTime;
      console.log("Audio time updated:", newTime);
      setCurrentTime(newTime);
    }
  }, [audioRef]);

  useEffect(() => {
    if (audioRef.current) {
      console.log("Adding timeupdate event listener");
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        console.log("Removing timeupdate event listener");
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [audioRef, handleTimeUpdate]);
  const handleLoadedMetadata = useCallback(() => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  }, []);

  const handleVolumeChange = useCallback((event, newValue) => {
    setVolume(newValue);
    if (audioRef.current) {
      audioRef.current.volume = newValue;
    }
  }, []);

  const handleYoutubeUrlChange = useCallback((e) => {
    setYoutubeUrl(e.target.value);
  }, []);

  const processAudioAndGenerateLyrics = useCallback(async (file) => {
    console.log("processAudioAndGenerateLyrics called with file:", file);
    if (!file) {
      toast.error('Vänligen välj en ljudfil eller ange en YouTube-URL först.');
      return;
    }

    if (!apiKey) {
      toast.error('Vänligen ange din OpenAI API-nyckel.');
      return;
    }

    setIsProcessing(true);
    setIsUploading(true);
    const toastId = toast.loading('Bearbetar ljud...');

    try {
      // Simulate file upload progress
      for (let i = 0; i <= 100; i += 10) {
        setUploadProgress(i);
        await new Promise(resolve => setTimeout(resolve, 200));
      }

      console.log("Starting transcription");
      setIsTranscribing(true);
      // Step 1: Transcribe audio
      const formData = new FormData();
      formData.append('file', file);
      formData.append('model', 'whisper-1');
      formData.append('response_format', 'verbose_json');
      formData.append('language', 'sv');
      formData.append('timestamp_granularities[]', 'segment');

      const transcriptionResponse = await fetch('https://api.openai.com/v1/audio/transcriptions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${apiKey}`
        },
        body: formData
      });

      if (!transcriptionResponse.ok) {
        const errorData = await transcriptionResponse.json();
        throw new Error(`API-fel vid transkribering: ${errorData.error?.message || transcriptionResponse.statusText}`);
      }

      const transcriptionData = await transcriptionResponse.json();
      console.log("Transcription data received:", transcriptionData);

      if (transcriptionData.segments && transcriptionData.segments.length > 0) {
        const timestampedTranscript = transcriptionData.segments.map(segment => {
          return `[${formatTimestamp(segment.start)}] ${segment.text}`;
        }).join('\n');
        console.log("Formatted transcript:", timestampedTranscript);
        setTranscript(timestampedTranscript);

        setIsTranscribing(false);
        toast.success('Transkribering klar', { id: toastId });

        // Step 2: Generate lyrics (only if checkbox is checked)
        if (generateLyrics) {
          console.log("Starting lyrics generation");
          setIsGeneratingLyrics(true);
          toast.loading('Genererar låttexter...', { id: toastId });
          const prompt = `Baserat på följande tidsstämplade transkription, skapa kreativa låttexter på svenska. Behåll tidsstämplarna från transkriptionen och anpassa texten för att passa en ${file.type.includes('mp3') ? 'pop' : 'akustisk'} låt som är ${Math.round(audioRef.current.duration)} sekunder lång. Transkription:\n\n${timestampedTranscript}. Inga Emojis eller andra icke-svenska tecken. Avsluta alltid med "Tack för att ni väljer Optagonen Workshop"`;

          const lyricsResponse = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${apiKey}`
            },
            body: JSON.stringify({
              model: "gpt-4o-mini",
              messages: [{ role: "user", content: prompt }],
              temperature: 0.7
            })
          });

          if (!lyricsResponse.ok) {
            const errorData = await lyricsResponse.json();
            throw new Error(`API-fel vid generering av låttext: ${errorData.error?.message || lyricsResponse.statusText}`);
          }

          const lyricsData = await lyricsResponse.json();
          console.log("Lyrics data received:", lyricsData);
          if (lyricsData.choices && lyricsData.choices[0] && lyricsData.choices[0].message) {
            const generatedLyricsContent = lyricsData.choices[0].message.content;
            console.log("Setting generated lyrics:", generatedLyricsContent);
            setGeneratedLyrics(generatedLyricsContent);
            setIsGeneratingLyrics(false);
            toast.success('Låttexter genererade', { id: toastId });
          } else {
            throw new Error('Oväntat svar från API vid generering av låttext');
          }
        }
      } else {
        throw new Error('Oväntat svar från API: Inga segment med tidsstämplar returnerades');
      }
    } catch (error) {
      console.error('Fel vid bearbetning av ljud och generering av låttext:', error);
      toast.error(`Fel: ${error.message}`, { id: toastId });
    } finally {
      setIsProcessing(false);
      setIsUploading(false);
      setUploadProgress(0);
      setIsTranscribing(false);
      setIsGeneratingLyrics(false);
    }
  }, [apiKey, generateLyrics, audioRef, setTranscript, setGeneratedLyrics]);


  const processYoutubeLink = useCallback(async () => {
    if (!youtubeUrl.trim()) {
      toast.error('Vänligen ange en YouTube-URL.');
      return;
    }
  
    setIsProcessing(true);
    const toastId = toast.loading('Bearbetar YouTube-video...');
  
    try {
      const response = await fetch(`${apiUrl}/api/process-youtube`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: youtubeUrl }),
      });
  
      if (!response.ok) {
        throw new Error('Fel vid bearbetning av YouTube-video');
      }
  
      const data = await response.json();
  
      // Convert base64 to Blob
      const byteCharacters = atob(data.audioData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'audio/mpeg' });
  
      // Create a File object
      const file = new File([blob], 'youtube-audio.mp3', { type: 'audio/mpeg' });
  
      setAudioFile(file);
      if (audioRef.current) {
        const fileURL = URL.createObjectURL(file);
        audioRef.current.src = fileURL;
        audioRef.current.load();
        setDuration(0); // Reset duration
        setCurrentTime(0); // Reset current time
      }
      toast.success('YouTube-ljud extraherat', { id: toastId });
  
      // Process the audio file and generate lyrics
      await processAudioAndGenerateLyrics(file);
    } catch (error) {
      console.error('Fel vid bearbetning av YouTube-video:', error);
      toast.error(`Fel: ${error.message}`, { id: toastId });
    } finally {
      setIsProcessing(false);
    }
  }, [youtubeUrl, processAudioAndGenerateLyrics]);

  return {
    isTranscribing,
    isGeneratingLyrics,
    audioFile,
    setAudioFile,
    transcript,
    generatedLyrics,
    apiKey,
    currentTime,
    duration,
    isPlaying,
    volume,
    youtubeUrl,
    isProcessing,
    generateLyrics,
    isUploading,
    uploadProgress,
    tabValue,
    handleApiKeyChange,
    handleFileChange,
    togglePlayPause,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleVolumeChange,
    handleYoutubeUrlChange,
    processAudioAndGenerateLyrics,
    processYoutubeLink,
    setGenerateLyrics,
    setTabValue,
    formatTime,
  };
};